import { ELEMENT } from "./constants";

import { diff } from "./diff";
import { concat } from "./vdom";
import { camelCase, root, append, defer } from "./utils";

export default class extends HTMLElement {
    constructor() {
        super();
        this[ELEMENT] = true;
        this.state = {};
        this.slots = {};
        this.props = {};
        this.fragment = document.createDocumentFragment();
        this._props = this.constructor.props || [];
        this._render = [];
        this._mount;
        this._prevent;
    }
    static get observedAttributes() {
        return ["children"].concat(this.props || []);
    }
    setAttribute(prop, value) {
        if (this._props.indexOf(prop) > -1) {
            this.setProps({ [prop]: value });
        } else {
            super.setAttribute(prop, value);
        }
    }
    /**
     * By default the children and properties are extracted
     * only when the component exists in the document
     * This is required for the component to be read regardless
     * of the load instance, in the same way it is applied asynchronously,
     * so as to be able to read the arguments generated by synchronous invocation,
     * be it the use of document.createElement
     */
    connectedCallback() {
        defer(() => {
            let children = this.props.children || [];
            while (this.firstChild) {
                let child = this.firstChild,
                    slot = child.getAttribute && child.getAttribute("slot");
                if (slot) {
                    this.slots[slot] = child;
                }
                append(this.fragment, child);
                children.push(child);
            }
            this.setProps({ children });
            this.setState({}, (this._mount = true));
            this.elementMount();
        });
    }
    disconnectedCallback() {
        this.elementUnmount();
    }
    setProps(props) {
        let nextProps = {},
            prevent = this._mount;
        for (let prop in props) {
            let index = camelCase(prop);
            if (props[prop] !== this.props[index]) {
                nextProps[index] = props[prop];
            }
        }
        if (Object.keys(nextProps).length) {
            if (prevent) prevent = this.elementReceiveProps(nextProps);
            this.props = { ...this.props, ...nextProps };
            if (prevent !== false && this._mount) this.setState({});
        }
    }
    attributeChangedCallback(index, prev, next) {
        prev !== next && this.setProps({ [index]: next });
    }
    dispatch(type, detail) {
        this.dispatchEvent(
            new CustomEvent(type, {
                cancelable: true,
                detail
            })
        );
    }
    setState(next, ignoreUpdate) {
        if (!next) return;
        this.state = { ...this.state, ...next };
        if (this._prevent) return;
        this._prevent = true;
        defer(() => {
            let render = concat([this.render()]);
            diff(root(this), this._render, render);
            this._render = render;
            this._prevent = false;
            if (!ignoreUpdate) this.elementUpdate(UPDATE);
        });
    }
    elementMount() {}
    elementUpdate() {}
    elementUnmount() {}
    elementReceiveProps() {}
    render() {}
}
